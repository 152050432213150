




















import { Component, Vue } from 'nuxt-property-decorator';
import { computed } from '@nuxtjs/composition-api';

import { useKeyValueStore } from '@/composables/key-value-store';

@Component({
  setup () {
    const phone = useKeyValueStore('contact_phone');
    const exchangePhone = useKeyValueStore('contact_exchange_phone');
    const email = useKeyValueStore('contact_email');
    const openingHoursUrl = useKeyValueStore('opening_hours_url');
    const formattedPhone = computed(() => phone.value?.replace(/[^\d]/g, ''));
    const formattedExchangePhone = computed(() => phone.value?.replace(/[^\d]/g, ''));

    return {
      phone,
      email,
      formattedPhone,
      openingHoursUrl,
      exchangePhone,
      formattedExchangePhone,
    };
  },
})
export default class SiteFooterContactList extends Vue {
  phone!: string;
  email!: string;
  formattedPhone!: string;
  openingHoursUrl!: string;
  exchangePhone!: string;
  formattedExchangePhone!: string;
}
