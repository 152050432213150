import Vue from 'vue';

export interface NavigationLinkProps {
  title: string;
  href: string;
  external?: boolean;
  children: NavigationLinkProps[];
}
interface NavigationState {
  mobileNavOpen: boolean,
  secondaryNavOpen: boolean,
}

export const state: NavigationState = Vue.observable({
  mobileNavOpen: false,
  secondaryNavOpen: true,
});

export const mutations = {
  toggleMobileNav () { state.mobileNavOpen = !state.mobileNavOpen },
  closeMobileNav () { state.mobileNavOpen = false },
  toggleSecondaryNav () { state.secondaryNavOpen = !state.secondaryNavOpen },
  closeSecondaryNav () { state.secondaryNavOpen = false },
};
