




















import { Component, Vue } from 'nuxt-property-decorator';

import { NavigationLinkProps, usePageNavigation } from '@/composables/page-navigation';

import { state } from '../NavigationState';

@Component({
  setup: () => usePageNavigation(),
})
export default class SiteHeaderSecondaryNav extends Vue {
  secondaryLinks!: NavigationLinkProps[];

  get secondaryNavOpen () {
    return state.secondaryNavOpen;
  }
}
