
























import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class Button extends Vue {
  @Prop({ type: String, default: 'button' })
  tag!: 'button' | 'nuxt-link' | 'div';

  @Prop({ type: String, required: false })
  to!: string;

  @Prop({
    type: String,
    default: null,
  })
  variant!: string;

  @Prop({
    type: String,
    required: false,
    validator (value) { return ['xs', 'sm', 'md', 'lg'].includes(value) },
  })
  size!: string;

  @Prop(Boolean)
  block!: boolean;

  @Prop({ type: Boolean, default: true })
  outline!: boolean;

  @Prop({ type: Boolean, default: null })
  disabled!: boolean;

  get sizeClass () {
    return this.size ? `-${this.size}` : '';
  }

  get isInternalLink () {
    return this.to && (this.to.startsWith('/') || this.to.startsWith('?'));
  }

  get isExternalLink () {
    return this.to && !this.isInternalLink;
  }

  get component () {
    if (this.isExternalLink)
      return 'a';

    if (this.isInternalLink)
      return 'nuxt-link';

    return this.tag;
  }

  get buttonProps () {
    if (this.isInternalLink) {
      let to: any = this.to;
      if (to.startsWith('?'))
        to = this.$route.path + to;

      return { to };
    }

    if (this.isExternalLink)
      return {
        href: this.to,
        rel: 'noopener noreferrer',
        target: '_blank',
      };

    if (this.tag === 'button')
      return { type: 'button' };

    return {};
  }
}
